.bind-main {
    padding-top: 20px;
}

.bind-main .title {
    font-size: 13px;
    font-weight: bold;
}

.bind-main .message {
    height: 20px;
    display: block;
}

.bind-main .success-message {
    color: #3cdf57;
}

.bind-main .error-message {
    color: #FF0000;
}

.bind-input {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 8px 10px;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
}

.bind-conform-btn {
    display: inline-block;
    background: #2bae82;
    border: none;
    color: #FFFFFF;
    padding: 20px 30px;
    margin-top: 50px;
    width: 100%;
}
